@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'IRANYekan';
  src: url('./assets/fonts/iranyekan/Farsi_numerals/WebFonts/fonts/eot/iranyekanwebmediumfanum.eot');
  src: url('./assets/fonts/iranyekan/Farsi_numerals/WebFonts/fonts/eot/iranyekanwebmediumfanum.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/iranyekan/Farsi_numerals/WebFonts/fonts/woff/iranyekanwebmediumfanum.woff') format('woff'),
  url('./assets/fonts/iranyekan/Farsi_numerals/WebFonts/fonts/ttf/iranyekanwebmediumfanum.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Aviny';
  src: url('./assets/fonts/aviny/aviny.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Katibeh';
  src: url('./assets/fonts/katibeh/katibeh.ttf') format('truetype'),
  url('./assets/fonts/katibeh/katibeh\ web.woff') format('woff'),
  url('./assets/fonts/katibeh/katibeh\ web.woff2') format('woff2');
  font-weight: normal;
}

body {
  margin: 0;
  font-family:  'IRANYekan', 'Aviny', 'Katibeh', 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a { 
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.visible {
  transition: opacity 0.5s;
  opacity: 1;
}
.visible.hide {
  opacity: 0;
  transition: opacity 0.5;
}

.icon-position{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

@media only screen and (max-width: 600px) {
  .icon-position{
    position: fixed;
    bottom: 150px;
    right: 20px;
    z-index: 20;
  }
}

.icon-style{
  background-color: black;
  opacity: 0.7;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  font-family: Aviny;
  font-size: 18px;
  /* animation: movebtn 3s ease-in-out infinite; */
  transition: all .5s ease-in-out;
}
.icon-style:hover{
  animation: none;
  background: #fff;
  color: black;
  border: 2px solid #E15A04;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 110px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 4px;
  border-radius: 6px;
  font-size: 12px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

a:link {
  color: black;
}

