.contactUsContainer {
    width: 90%;
    margin: 0px auto 80px;
    border: 2px solid #C69C61;
    box-sizing: border-box;
    border-radius: 30px;
    direction: rtl;
    padding: 40px 30px;
    @media (max-width: 800px) {
        padding: 40px 20px;
        width: calc(100% - 40px);
        margin: 0px auto 80px;
    }
    & > p {
        font-family: IRANYekan;
        font-style: normal;
        font-size: 18px;
        text-align: right;
        color: #000000;
        margin: 0px 0px 10px;
        @media (max-width: 800px) {
            font-size: 12px;
        }
    }
    & > p:nth-child(2), :nth-child(6), :nth-child(8), :nth-child(11), :nth-child(15) {
        font-family: Aviny;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        margin: 30px 0px 19px;
        @media (max-width: 800px) {
            font-size: 20px;
        }
        & > span {
            color: #C69C61;
            margin-right: 8px;
            font-size: 16px;
            @media (max-width: 800px) {
                font-size: 12px;
            }
        }
    }
    & > div:nth-child(10) {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        & > div:nth-child(1) {
            display: flex;
            flex-direction: row;
            align-items: center;
            & > img {
                width: 24px;
                height: 24px;
                display: block;
            }
            &  > p {
                font-family: IRANYekan;
                margin: 0px;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                @media (max-width: 800px) {
                    font-size: 12px;
                }
            }
            & > p:nth-child(2) {
                margin: 0px 20px;
            }
        }
        & > div:nth-child(2) {
            display: flex;
            flex-direction: row;
            align-items: center;
            & > a:nth-child(1) {
                margin-left: 30px;
            }
            & > a {
                margin-top: 20px;
                & > img {
                    width: 30px;
                }
            }
        }
        
    }
    & > div:nth-child(7) {
        display: flex;
        flex-direction: column;
        & > p {
            font-family: IRANYekan;
            font-style: normal;
            font-size: 18px;
            text-align: right;
            color: #000000;
            margin: 0px 0px 10px;
            @media (max-width: 800px) {
                font-size: 12px;
            }
        }
        & > div:nth-child(2) {
            display: flex;
            flex-direction: row;
            align-items: center;
            @media (max-width: 800px) {
                flex-direction: column;
            }
            & > div {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 20px;
                width: 400px;
                @media (max-width: 800px) {
                    width: 100%;
                }
                & > img {
                    width: 24px;
                    height: 24px;
                    display: block;
                }
                &  > p {
                    font-family: IRANYekan;
                    margin: 0px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    @media (max-width: 800px) {
                        font-size: 12px;
                    }
                }
                & > p:nth-child(2) {
                    margin: 0px 20px;
                }
            }
        }
        & > div:nth-child(3) {
            display: flex;
            flex-direction: row;
            align-items: center;
            & > a:nth-child(1) {
                margin-left: 30px;
            }
            & > a {
                margin-top: 20px;
                & > img {
                    width: 30px;
                }
            }
        }
    }
}