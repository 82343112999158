.toomarCardContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    height: 356px;
    margin: 0px auto 80px;
    background: #FFFFFF;
    box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
    border-radius: 10px;
    // padding-bottom: 30px;
    position: relative;
    transition: all .2s ease-in-out; 
    @media (max-width: 800px) {
        margin-bottom: 30px;
    }
    & > img {
        width: 100%;
        height: 239px;
        object-fit: cover;
        border-radius: 8px 8px 0px 0px;
    }
    & > div {
        width: 100%;
    }
    & > .overflowTitle {
        height: 87px;
    }
    & > div > p {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #000000;
        text-align: right;
        width: 80%;
        margin: 5px 10px 20px 20%;
        direction: rtl;
    }
    & > .footerCards {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 20px);
        direction: rtl;
    }
    & > .footerCards > .productCode > span {
        font-weight: bold;
        font-size: 12px;
        line-height: 31px;
        color: #000000;
    }
    & > .footerCards > .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        direction: ltr;
        & > span:nth-child(1) {
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
            color: #000000;
        }
        & > span:nth-child(2) {
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
            color: #000000;
            margin: 0px 10px;
        }
        & > span:nth-child(3) {
            font-weight: bold;
            font-size: 12px;
            line-height: 31px;
            color: #000000;
            text-decoration: line-through;
        }
    }
    & > .offPercent {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #E45803;
        width: 55px;
        height: 62px;
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 8px 0px 0px;
    }

    & > .suggestContainer {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #E45803;
        width: 140px;
        height: 50px;
        font-weight: bold;
        line-height: 20px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        & > p {
            font-size: 12px;
            line-height: 20px;
            margin: 0px;
        }
    }
    & > .favoriteContainer {
        position: absolute;
        top: 0px;
        left: 0px;
        background: rgba(255, 255, 255, 0.5);
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 10px;
        & > img {
            width: 30px;
            height: 30px;
        }
    }

}

.toomarCardContainer:hover {
    transform: scaleX(1.05); 
    box-shadow: -2px 10px 9px rgba(0, 0, 0, 0.3);
    & > img {
        border-radius: 8px 8px 0px 0px;
    }
}

.toomarCardGalleryContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    margin: 0px auto 80px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
    border-radius: 10px;
    // padding-bottom: 30px;
    position: relative;
    transition: all .2s ease-in-out; 
    @media (max-width: 800px) {
        margin-bottom: 30px;
    }
    & > a {
        width: 100%;
    }
    & > a > img {
        width: 100%;
        object-fit: cover;
        border-radius: 8px 8px 0px 0px;
    }
    & > a > div {
        width: 100%;
    }
    & > a > .overflowTitle {
        height: 87px;
    }
    & > a > div > p {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #000000;
        text-align: right;
        width: 80%;
        margin: 5px 10px 20px 20%;
        direction: rtl;
    }
    & > a > .footerCards {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 20px);
        direction: rtl;
    }
    & > a > .footerCards > .productCode > span {
        font-weight: bold;
        font-size: 12px;
        line-height: 31px;
        color: #000000;
    }
    & > a > .footerCards > .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        direction: ltr;
        & > span:nth-child(1) {
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
            color: #000000;
        }
        & > span:nth-child(2) {
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
            color: #000000;
            margin: 0px 10px;
        }
        & > span:nth-child(3) {
            font-weight: bold;
            font-size: 12px;
            line-height: 31px;
            color: #000000;
            text-decoration: line-through;
        }
    }
    & > a > .offPercent {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #E45803;
        width: 55px;
        height: 62px;
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 8px 0px 0px;
    }

    & > a > .suggestContainer {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #E45803;
        width: 140px;
        height: 50px;
        font-weight: bold;
        line-height: 20px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        & > p {
            font-size: 12px;
            line-height: 20px;
            margin: 0px;
        }
    }
    & > .favoriteContainer {
        position: absolute;
        top: 0px;
        left: 0px;
        background: rgba(255, 255, 255, 0.5);
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 10px;
        & > img {
            width: 30px;
            height: 30px;
        }
    }

}

.toomarCardGalleryContainer:hover {
    transform: scaleX(1.05); 
    box-shadow: -2px 10px 9px rgba(0, 0, 0, 0.3);
    & > a > img {
        border-radius: 8px 8px 0px 0px;
    }
}

.toomarBlogCardContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    margin: 0px auto 80px;
    background: #FFFFFF;
    box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
    border-radius: 10px;
    padding-bottom: 30px;
    position: relative;
    transition: all .2s ease-in-out; 
    & > img {
        width: 100%;
        height: 240px;
        object-fit: cover;
    }
    & > p {
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        color: #000000;
        text-align: right;
        width: 80%;
        margin: 5px 10px 20px 20%;
    }
    & > .more {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        direction: rtl;
        padding-left: 30px;
        cursor: pointer;
        & > span:nth-child(1) {
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
            color: #000000;
            margin-left: 10px;
        }
    }
    & > .date {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #C69C61;
        width: 130px;
        height: 62px;
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
    }
}

.toomarBlogCardContainer:hover {
    transform: scale(1.05); 
    box-shadow: -2px 10px 9px rgba(0, 0, 0, 0.3);
}

.favCardContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    // height: 100%;
    margin: 0px auto 20px;
    background: #FFFFFF;
    box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
    border-radius: 10px;
    // padding-bottom: 30px;
    position: relative;
    transition: all .2s ease-in-out; 
    @media (max-width: 800px) {
        margin-bottom: 30px;
    }
    & > a {
        width: 100%;
    }
    & > a > img {
        width: 100%;
        object-fit: cover;
        border-radius: 8px 8px 0px 0px;
    }
    & > a > div {
        width: 100%;
    }
    & > a > .overflowTitle {
        height: 87px;
    }
    & > a > div > p {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #000000;
        text-align: right;
        width: 80%;
        margin: 5px 10px 20px 20%;
        direction: rtl;
    }
    & > a > .footerCards {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 20px);
        direction: rtl;
    }
    & > a > .footerCards > .productCode > span {
        font-weight: bold;
        font-size: 12px;
        line-height: 31px;
        color: #000000;
    }
    & > a > .footerCards > .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        direction: ltr;
        & > span:nth-child(1) {
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
            color: #000000;
        }
        & > span:nth-child(2) {
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
            color: #000000;
            margin: 0px 10px;
        }
        & > span:nth-child(3) {
            font-weight: bold;
            font-size: 12px;
            line-height: 31px;
            color: #000000;
            text-decoration: line-through;
        }
    }
    & > a > .offPercent {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #E45803;
        width: 55px;
        height: 62px;
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 8px 0px 0px;
    }

    & > a > .suggestContainer {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #E45803;
        width: 140px;
        height: 50px;
        font-weight: bold;
        line-height: 20px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        & > p {
            font-size: 12px;
            line-height: 20px;
            margin: 0px;
        }
    }
    & > .favoriteContainer {
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: 0px;
        background: rgba(255, 255, 255, 0.5);
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 10px;
        z-index: 50;
        & > img {
            width: 30px;
            height: 30px;
        }
    }

}

.favCardContainer:hover {
    transform: scaleX(1.05); 
    box-shadow: -2px 10px 9px rgba(0, 0, 0, 0.3);
    & > a > img {
        border-radius: 8px 8px 0px 0px;
    }
}
