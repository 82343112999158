.aboutUsContainer {
    width: 90%;
    margin: 0px auto 80px;
    border: 2px solid #C69C61;
    box-sizing: border-box;
    border-radius: 30px;
    direction: rtl;
    padding: 40px 30px;
    & > p {
        font-family: IRANYekan;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 40px;
        text-align: right;
        margin: 0px 0px 0px;
        & > span {
            color: #C69C61;
            cursor: pointer;
        }
        @media (max-width: 800px) {
            font-size: 12px;
            text-align: center;
        }
    }
}