.loadingImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.toomarCardsSwiperContainer {
    width: 100%;
}

.toomarCardsSwiperItemContainer {
    width: 100%!important;
    & > img {
        width: 100%;
        object-fit: cover;
        height: 540px;
        @media (max-width: 800px) {
            width: 100%;
        }
    }
}

.toomarCardsSwiper > .swiper-button-next:after {
    color: white;
    cursor: pointer;
    @media (max-width: 800px) {
        font-size: 30px;
    }
}

.swiper-pagination-bullet {
    background-color: rgba(228, 228, 228, 1)!important;
    opacity: 1!important;
}

.toomarCardsSwiper > .swiper-button-prev:after {
    color: white;
    @media (max-width: 800px) {
        font-size: 30px;
    }
}

.toomarCardsSwiper > .swiper-pagination > .swiper-pagination-bullet-active {
    background-color: #E45803!important;
}

.toomarCardsSwiper > div > .swiper-slide > a > div {
    width: 70%;
}

.toomarObiturySwiper > .swiper-button-next:after {
    color: black!important;
}

.toomarObiturySwiper > .swiper-button-prev:after {
    color: black!important;
}

.obituryOfToomarContainer {
    background: linear-gradient(180deg, rgba(191, 150, 92, 0.20) 0%, rgba(255, 240, 219, 0.20) 100%);
    padding: 0px 100px;
    @media (max-width: 800px) {
        padding: 0px 20px;
    }
}

.toomarMostSoldContainer {
    display: flex;
    flex-direction: row;
    background: black;
    align-items: center;
    direction: rtl;
    padding: 60px 50px;
    margin-top: -8px;
    @media (max-width: 800px) {
        padding: 40px 20px;
        justify-content: space-between;
    }
    & > div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 20%;
        @media (max-width: 800px) {
            width: 35%;
            margin-left: 8px;
        }
        & > p:first-child {
            font-family: Aviny;
            font-size: 48px;
            color: white;
            margin: 0px 0px 20px;
            @media (max-width: 800px) {
                font-size: 24px;
            }
        }
        & > p:last-child {
            font-family: Aviny;
            font-size: 48px;
            margin: 0px;
            @media (max-width: 800px) {
                font-size: 24px;
            }
        }
    }
    & > div:last-child {
        width: 80%;
        @media (max-width: 800px) {
            width: 60%;
            & > div > .swiper-wrapper > div > a > div {
                width: 95%!important;
            }
        }

        .toomarMostSold {
            overflow: visible;
        }

        .toomarMostSold > .swiper-button-next:after {
            color: black;
            font-size: 24px;
            @media (max-width: 800px) {
            }
        }

        .toomarMostSold > .swiper-button-next {
            width: 62px!important;
            height: 48px!important;
            background-color: white;
            left: 18px!important;
            @media (max-width: 800px) {
                left: 5px!important;
            }
        }
        
        .toomarMostSold > .swiper-button-prev {
            width: 62px!important;
            height: 48px!important;
            background-color: white;
            right: 18px!important;
            @media (max-width: 800px) {
                right: 5px!important;
            }
        }
        
        .toomarMostSold > .swiper-button-prev:after {
            color: black;
            font-size: 24px;
            @media (max-width: 800px) {
            }
        }
        .toomarMostSold > .swiper-scrollbar {
            display: none;
        }

        .toomarMostSold > .swiper-wrapper > div > div {
            margin-bottom: 0px;
        }
        
        .toomarMostSold > .swiper-wrapper > div > a > div {
            width: 90%;
            @media (max-width: 800px) {
                width: 100%;
                height: auto;
            }
        }
    }
}

.toomarGalleryContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 0px;
        width: calc(100% - 180px);
        @media (max-width: 800px) {
            width: calc(100% - 40px);
            padding: 40px 20px;
            
        }
        & > div:first-child {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 100%;
            direction: rtl;
            overflow-x: scroll;
            overflow-y: hidden;
            @media (max-width: 800px) {
                padding: 0px;
                width: calc(100%);
                display: grid;
                grid-template-columns: auto auto;
            }
            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 66px;
                cursor: pointer;
                width: 200px;
                height: 100px;
                margin: auto;
                & > .isNotActive {
                    font-weight: bold;
                    font-size: 16px;
                    color: black;
                    margin: 0px 0px 20px;
                    width: 200px;
                    text-align: center;
                    @media (max-width: 800px) {
                        font-size: 16px;
                    }
                }
                & > .isActive {
                    font-weight: bold;
                    font-size: 16px;
                    color: #EABA77;
                    margin: 0px 0px 20px;
                    width: 200px;
                    text-align: center;
                    @media (max-width: 800px) {
                        font-size: 16px;
                    }
                }
                & > img {
                    width: 100%;
                }
                & > p:hover {
                    color: #EABA77;
                }
            }
        }
        & > div:last-child {
            margin-top: 53px;
            display: grid;
            grid-template-columns: 33.33333% 66.66667%;
            row-gap: 40px;
            column-gap: 40px;
            width: 80%;
            direction: rtl;
            @media (max-width: 800px) {
                grid-template-columns: calc(33.33333% - 10px) calc(66.66667% - 10px);
                row-gap: 20px;
                column-gap: 20px;
                width: 100%;
            }
            & > div {
                position: relative;
                & > a > img {
                    width: 100%;
                    display: block;
                    object-fit: cover;
                    height: 100%;
                }
                & > a > div {
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                    background: #FFF9F7;
                    & > p {
                        font-family: Aviny;
                        font-size: 24px;
                        color: #000000;
                        margin: 0px;
                        padding: 8px 12px;
                        @media (max-width: 800px) {
                            font-size: 16px;
                            padding: 4px;
                        }
                    }
                }
            }

            & > div:last-child {
                width: 100%;
            }
        }
    }
}

.linkToWeddingContainer {
    background-image: url('../../assets/icons/linkToWedding.svg');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 240px;
    width: 100%;
    background-size: cover;
    & > p {
        color: #FFF;
        font-size: 40px;
        font-family: Aviny;
        margin: 0px;
        @media (max-width: 800px) {
            font-size: 24px;
        }
    }
    & > a > button {
        border-radius: 13px;
        border: 1px solid #FFF;
        padding: 14px 130px;
        background-color: transparent;
        color: #FFF;
        font-size: 24px;
        font-family: IRANYekan;
        margin-top: 20px;
        font-weight: 700;
        cursor: pointer;
        @media (max-width: 800px) {
            padding: 10px 40px;
            font-size: 20px;
            font-weight: 500;
        }
    }
}

.custom-banner {
    & > img {
        width: 100%;
        margin: 0px 0px 0px;
    }
}
