.productContainer {
    padding: 0px 50px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 229px calc(100% - 279px);
    column-gap: 30px;
    width: calc(95% - 50px);
    grid-template-columns: 229px calc(100% - 279px);
    direction: rtl;
    margin: 10px auto 0px;
    @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        width: calc(100%);
        padding: 0px 0px;
        column-gap: 0px;
        
    }
    & > div:first-child {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            @media (max-width: 800px) {
                display: none;
            }
            & > p {
                font-family: Aviny;
                font-size: 28px;
                color: #000000;
                margin: 0px 0px 20px 0px;
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                width: 100%;
            }
            & > div {
                margin-bottom: 20px;
            }
        }
        
    }
    & > div:last-child {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: linear-gradient(to bottom, rgb(28, 26, 29), rgb(0, 44, 60));
        padding: 16px 20px;
        border-radius: 8px;
        @media (max-width: 800px) {
            width: auto;
            border-radius: 0px;
        }
        & > div:nth-child(1) {
            display: grid;
            grid-template-columns: calc(100% - 555px) 505px;
            column-gap: 50px;
            margin-bottom: 20px;
            @media (max-width: 800px) {
                display: flex;
                flex-direction: column-reverse;
            }
            & > div:nth-child(1) {
                display: flex;
                flex-direction: column;
                width: 100%;
                & > * {
                    width: 100%;
                }
                & > p:nth-child(1) {
                    font-family: Aviny;
                    font-size: 32px;
                    color: white;
                    margin: 0px;
                    @media (max-width: 800px) {
                        font-size: 24px;
                        margin-top: 20px;
                    }
                }
                & > p:nth-child(2) {
                    font-family: IRANYekan;
                    font-weight: bold;
                    font-size: 16px;
                    color: white;
                    margin: 10px 0px;
                    @media (max-width: 800px) {
                        font-size: 14px;
                    }
                }
                & > div:nth-child(3) {
                    & > p:nth-child(1) {
                        font-family: Aviny;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 24px;
                        color: white;
                    }
                    & > div > p:nth-child(2) {
                        font-family: IRANYekan;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        color: white;
                    }
                    & > div > * {
                        font-family: IRANYekan!important;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        color: white;
                    }
                }
                & > div:nth-child(4) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 20px 0px 0px;
                    & > p {
                        font-family: IRANYekan;
                        font-weight: bold;
                        font-size: 16px;
                        color: white;
                        margin: 0px 0px 10px;
                        @media (max-width: 800px) {
                            font-size: 14px;
                        }
                    }
                    & > div {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        background: #FFFFFF;
                        border: 1px solid #E4E4E4;
                        box-sizing: border-box;
                        border-radius: 5px;
                        width: 150px;
                        margin-right: 15px;
                        padding: 0px 10px;
                        height: 50px;
                        @media (max-width: 800px) {
                            padding: 5px 15px;
                        }
                        & > p {
                            font-family: IRANYekan;
                            font-weight: bold;
                            font-size: 20px;
                            color: black;
                            margin: 0px;
                            cursor: pointer;
                            @media (max-width: 800px) {
                                font-size: 14px;
                            }
                        }
                        & > input:nth-child(2) {
                            margin: 0px 15px;
                            border: none;
                            width: 50px;
                            text-align: center;
                            font-family: IRANYekan;
                            height: 90%;
                        }
                        & > input:focus {
                            outline: none;
                        }
                        & > p:nth-child(1), :nth-child(3) {
                            font-size: 28px;
                            @media (max-width: 800px) {
                                font-size: 20px;
                            }
                        }
                    }
                }
                & > div:nth-child(5) {
                    margin: 16px 0px;
                    width: 200px;
                    & > select {
                        padding: 4px 12px;
                        border-radius: 8px;
                        font-family: IRANYekan;
                        border-radius: 8px;
                        background-color: white;
                        width: 200px;
                    }
                }
                & > div:nth-child(6) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    & > p:nth-child(1) {
                        font-family: IRANYekan;
                        font-weight: bold;
                        font-size: 24px;
                        color: white;
                        margin: 15px 0px 10px;
                        text-align: left;
                        @media (max-width: 800px) {
                            font-size: 14px;
                        }
                    }
                    & > p:nth-child(2) {
                        font-family: IRANYekan;
                        font-weight: bold;
                        font-size: 18px;
                        color: white;
                        margin: 15px 0px 10px;
                        text-align: left;
                        @media (max-width: 800px) {
                            font-size: 14px;
                        }
                    }
                }
                & > div:nth-child(7) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    & > p:nth-child(1) {
                        font-family: IRANYekan;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 13px;
                        color: #7A7A7A;
                        text-decoration: line-through;
                        margin: 0px 0px 0px 20px;
                    }
                    & > p:nth-child(2) {
                        font-family: IRANYekan;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 18px;
                        color: #FFFFFF;
                        margin: 0px;
                        background: #E45803;
                        padding: 10px;
                    }
                }
                & > div:nth-child(8) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 80%;
                    margin: 20px auto 0px;
                    @media (max-width: 800px) {
                        flex-direction: column;
                    }
                    & > button {
                        width: 65%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        height: 55px;
                        border-radius: 5px;
                        cursor: pointer;
                        @media (max-width: 800px) {
                            width: 70%;
                        }
                    } 
                    & > button:nth-child(1) {
                        font-family: IRANYekan;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        color: #FFFFFF;
                        background: #E45803;
                        border: none;
                        @media (max-width: 800px) {
                            margin-bottom: 10px;
                        }
                    }
                    & > button:nth-child(2) {
                        border: 1px solid black;
                        background-color: white;
                        & > img {
                            width: 14px;
                            margin-left: 5px;
                        }
                        & > p {
                            margin: 0px;
                            font-family: IRANYekan;
                            font-weight: bold;
                            font-size: 16px;
                            color: white;
                        }
                    }
                    & > button:focus {
                        outline: none;
                    }
                }
                & > p:nth-child(9) {
                    font-family: IRANYekan;
                    font-weight: bold;
                    font-size: 16px;
                    color: white;
                    margin: 0px;
                    @media (max-width: 800px) {
                        font-size: 14px;
                    }
                }
                
                & > p:nth-child(10) {
                    font-family: IRANYekan;
                    font-weight: bold;
                    font-size: 14px;
                    color: #BD945B;
                    margin: 20px auto 0px;
                    text-align: center;
                }
                
                // & > div:nth-child(11) {
                //     display: flex;
                //     flex-direction: row;
                //     align-items: center;
                //     justify-content: center;
                //     width: 80%;
                //     margin: 20px auto 0px;
                //     @media (max-width: 800px) {
                //         flex-direction: column;
                //     }
                //     & > button {
                //         width: 65%;
                //         display: flex;
                //         flex-direction: row;
                //         align-items: center;
                //         justify-content: center;
                //         height: 55px;
                //         border-radius: 5px;
                //         cursor: pointer;
                //         @media (max-width: 800px) {
                //             width: 70%;
                //         }
                //     } 
                //     & > button:nth-child(1) {
                //         font-family: IRANYekan;
                //         font-style: normal;
                //         font-weight: bold;
                //         font-size: 16px;
                //         color: #FFFFFF;
                //         background: #E45803;
                //         border: none;
                //         @media (max-width: 800px) {
                //             margin-bottom: 10px;
                //         }
                //     }
                //     & > button:nth-child(2) {
                //         border: 1px solid black;
                //         background-color: white;
                //         & > img {
                //             width: 14px;
                //             margin-left: 5px;
                //         }
                //         & > p {
                //             margin: 0px;
                //             font-family: IRANYekan;
                //             font-weight: bold;
                //             font-size: 16px;
                //             color: #000000;
                //         }
                //     }
                //     & > button:focus {
                //         outline: none;
                //     }
                // }
            }
            & > div:nth-child(10) {
                & > p:nth-child(1) {
                    font-family: Aviny;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                }
                & > div > p:nth-child(2) {
                    font-family: IRANYekan;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                }
            }
            & > div:nth-child(2) {
                & > div:last-child {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin: 10px 0px 0px;
                    @media (max-width: 800px) {
                        width: 60%;
                        margin-right: 40%;
                    }
                    & > p {
                        font-family: IRANYekan;
                        font-weight: bold;
                        font-size: 16px;
                        color: white;
                        margin: 0px 0px 0px 10px;
                        @media (max-width: 800px) {
                            display: none;
                        }
                    }
                    & > a > button {
                        width: 64px;
                        height: 56px;
                        border-radius: 9px;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        @media (max-width: 800px) {
                            width: 50px;
                            height: 50px;
                        }
                        & > img {
                            width: 24px;
                        }
                    }
                    & > a:nth-child(2) > button {
                        background: #8497FF;
                    }
                    & > a:nth-child(3) > button {
                        background: #0BAA4A;
                    }
                    & > a:nth-child(4) > button {
                        background: #39AFFD;
                    }
                }
            }
        }
        & > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-bottom: 1px solid rgba(228, 228, 228, 0.63);
            margin-bottom: 20px;
            padding-bottom: 15px;
            & > p:nth-child(1) {
                font-family: Aviny;
                font-size: 24px;
                color: white;
                margin: 0px;
                border-bottom: 1px solid #E45803;
                padding-bottom: 10px;
                width: max-content;
            }
            & > div > * {
                font-family: IRANYekan!important;
                color: white;
            }
            & > div > p:nth-child(2), :nth-child(3), :nth-child(4) {
                font-family: IRANYekan;
                font-weight: bold;
                font-size: 18px;
                color: white;
                margin: 0px;
            }
            & > p:nth-child(2) {
                margin: 10px 0px;
            }
            & > p:nth-child(4) {
                margin: 10px 0px;
            }
        }

        & > div:nth-child(3) {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;
            padding-bottom: 15px;
            & > p:nth-child(1) {
                font-family: Aviny;
                font-size: 24px;
                color: white;
                margin: 0px;
                border-bottom: 1px solid #E45803;
                padding-bottom: 10px;
                width: max-content;
                margin-bottom: 30px;
            }
            & > div {
                display: flex;
                flex-direction: column;
                padding-bottom: 25px;
                margin: 0px 0px 25px;
                border-bottom: 1px solid rgba(228, 228, 228, 0.63);
                & > div:nth-child(1) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    & > img {
                        width: 35px;
                        margin-left: 12px;
                    }
                    & > div {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        & > p:nth-child(1) {
                            font-family: IRANYekan;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            margin: 0px 0px 5px;
                        }
                        & > p:nth-child(2) {
                            font-family: IRANYekan;
                            font-weight: bold;
                            font-size: 14px;
                            color: #8E8E8E;
                            margin: 0px;
                        }
                    }
                }
                & > p:nth-child(2) {
                    font-family: IRANYekan;
                    font-weight: bold;
                    font-size: 14px;
                    color: white;
                    margin: 15px 0px 0px;
                } 
                & > div:nth-child(3) {
                    display: flex;
                    flex-direction: column;
                    margin-right: 32px;
                    margin-top: 20px;
                    & > div {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        & > img {
                            width: 35px;
                            margin-left: 12px;
                        }
                        & > div {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            & > p:nth-child(1) {
                                font-family: IRANYekan;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 14px;
                                margin: 0px 0px 5px;
                            }
                            & > p:nth-child(2) {
                                font-family: IRANYekan;
                                font-weight: bold;
                                font-size: 14px;
                                color: #8E8E8E;
                                margin: 0px;
                            }
                        }
                    }
                    & > p {
                        font-family: IRANYekan;
                        font-weight: bold;
                        font-size: 14px;
                        color: white;
                        margin: 15px 0px 0px;
                    }
                }
            }
            & > button {
                width: 217px;
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #E15A04;
                box-sizing: border-box;
                border-radius: 5px;
                font-family: IRANYekan;
                font-weight: bold;
                font-size: 18px;
                color: #E45803;
                margin: 0px auto;
                background-color: transparent;
                cursor: pointer;
            }
            & > button:focus {
                outline: none;
            }
        }

        & > div:nth-child(4) {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;
            padding-bottom: 15px;
            & > p:nth-child(1) {
                font-family: Aviny;
                font-size: 24px;
                color: white;
                margin: 0px;
                border-bottom: 1px solid #E45803;
                padding-bottom: 10px;
                width: max-content;
                margin-bottom: 30px;
                @media (max-width: 800px) {
                    font-size: 20px;
                }
            }
            & > div {
                // display: grid;
                width: 100%;
                & > .toomarCardsSwiperSameProductsVideo {
                    & > .swiper-button-next::after {
                        color: black;
                        margin-right: 18px;
                        @media (max-width: 800px) {
                            font-size: 24px;
                            margin-right: 32px;
                            background-color: #FBFAFA;
                        }
                    }
                    
                    & > .swiper-button-prev:after {
                        color: black;
                        margin-left: 18px;
                        @media (max-width: 800px) {
                            font-size: 24px;
                            margin-left: 32px;
                            background-color: #FBFAFA;
                        }
                    }
                    // @media (max-width: 800px) {
                        & > .swiper-pagination  {
                            display: none;
                        }
                        & > .swiper-wrapper {
                            width: 95%;
                            display: flex;
                            margin: auto;
                            @media (max-width: 800px) {
                                width: 100%;
                            }
                        }
                        & > .swiper-wrapper > div {
                            display: flex;
                            width: 33.3333%!important;
                            align-items: center;
                            justify-content: center;
                            @media (max-width: 800px) {
                                width: 100%!important;
                            }
                        }
                    // }
                }
                
                
                // grid-template-columns: calc((100% - 60px)/3) calc((100% - 60px)/3) calc((100% - 60px)/3);
                // column-gap: 30px;
                // & > a > img {
                //     width: 100%;
                //     height: 240px;
                //     object-fit: cover;
                // }
            }
        }
        & > div:nth-child(5) {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;
            padding-bottom: 15px;
            & > p:nth-child(1) {
                font-family: Aviny;
                font-size: 24px;
                color: white;
                margin: 0px;
                border-bottom: 1px solid #E45803;
                padding-bottom: 10px;
                width: max-content;
                margin-bottom: 30px;
            }
            & > div {
                & > div > .swiper-wrapper > div > a > div {
                    @media (max-width: 800px) {
                        width: 90%;
                    }
                }
                .toomarCardsSwiperSameProducts > .swiper-button-next:after {
                    color: white;
                    margin-right: 24px;
                }
                .toomarCardsSwiperSameProducts > .swiper-button-prev:after {
                    color: white;
                    margin-left: 24px;
                }
                @media (max-width: 800px) {
                    .toomarCardsSwiperSameProducts > .swiper-button-next:after {
                        color: black;
                        margin-right: 35px;
                        @media (max-width: 800px) {
                            font-size: 24px;
                            margin-right: 64px;
                            background-color: transparent;
                        }
                    }
                    
                    .toomarCardsSwiperSameProducts > .swiper-button-prev:after {
                        color: black;
                        margin-left: 35px;
                        @media (max-width: 800px) {
                            font-size: 24px;
                            margin-left: 64px;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}

.topImageSwiper {
    width: 100%;
    height: 373px;
    object-fit: cover;
    @media (max-width: 800px) {
        width: 100%;
    }
}

.bottomImagesSwiper {
    width: 100%;
    height: 87px;
    object-fit: cover;
    @media (max-width: 800px) {
        width: 100%;
    }
}

.sameProductsItem {
    width: 80%;
    height: 240px;
    object-fit: cover;
    margin: 0px 10%;
}

.toomarCardsSwiperSameProducts > .swiper-button-next:after {
    color: black;
    @media (max-width: 800px) {
        font-size: 30px;
        margin-left: 33px;
        background-color: #FBFAFA;
    }
}

.toomarCardsSwiperSameProducts > .swiper-button-prev:after {
    color: black;
    @media (max-width: 800px) {
        font-size: 30px;
        margin-right: 33px;
        background-color: #FBFAFA;
    }
}

.toomarCardsSwiperSameProducts > .swiper-pagination > .swiper-pagination-bullet-active {
    background-color: #E45803;
}

.commentContainer {
    padding: 25px 20px;
    width: calc(100% - 40px);
    direction: rtl;
    & > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 8px;
        cursor: pointer;
        & > img {
            width: 24px;
            height: 24px;
        }
        & > p {
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            margin: 0px;
            margin-left: 10px;
        }
    }
    & > img:nth-child(2) {
        width: 40%;
        margin: 0px 30%;
    }
    & > p:nth-child(3) {
        font-family: 'Aviny';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 38px;
        text-align: center;
        color: #916F40;
    }
    & > div:nth-child(8) {
        display: flex;
        flex-direction: column;
        & > p {
            font-family: Aviny;
            font-size: 24px;
            line-height: 38px;
            margin: 0px 0px 6px;
        }
        & > select {
            padding: 15px;
            width: 100%;
            border: 1px solid #E4E4E4;
            box-sizing: border-box;
            border-radius: 5px;
            font-family: iranYekan;
            font-size: 15px;
        }
        
    }
    & > button:nth-child(10) {
        border: 1px solid #E15A04;
        box-sizing: border-box;
        border-radius: 5px;
        width: 40%;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 31px;
        background-color: white;
        font-family: iranYekan;
        color: #E45803;
        margin-top: 20px;
        padding: 5px 0px;
        margin-right: 60%;
        cursor: pointer;
    }
}

.categoriesContainer {
    & > .parent {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        cursor: pointer;
        & > p {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            margin: 0px;
        }
    }
    & > .childrenGallery {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0px auto 10px;
        cursor: pointer;
        & > a:nth-child(1) {
            font-size: 14px;
            margin: 0px;
            line-height: 24px;
            color: black;
        }
        & > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
            & > p {
                font-size: 14px;
                margin: 4px 0px;
                line-height: 24px;
            }
        }
        // & > p:nth-child(1) {
        //     font-weight: bold;
        //     font-size: 24px;
        //     margin: 0px 0px 0px 10px;
        //     padding-bottom: 10px;
        //     line-height: 20px;
        // }
        // & > p:nth-child(2) {
        //     font-size: 14px;
        //     margin: 0px;
        //     line-height: 24px;
        // }
        // & > a:nth-child(1) {
        //     font-weight: bold;
        //     font-size: 24px;
        //     margin: 0px 0px 0px 10px;
        //     padding-bottom: 10px;
        //     line-height: 20px;
        //     color: black;
        // }
        // & > a:nth-child(2) {
        //     font-size: 14px;
        //     margin: 0px;
        //     line-height: 24px;
        //     color: black;
        // }
    }
}

.suggestions {
    & > div > .swiper-wrapper > div > a > div {
        width: 100%;
        height: auto;
        & > p {
            width: 80%;
            margin-left: 20%;
            text-overflow: hidden;
        }
    }
}

.toomarCardsSwiperSameProductsSidebar > .swiper-button-next:after {
    color: white;
    margin-bottom: 20px;
    font-weight: bold;
    @media (max-width: 800px) {
        font-size: 30px;
    }
}

.toomarCardsSwiperSameProductsSidebar > .swiper-button-prev:after {
    color: white;
    margin-bottom: 20px;
    font-weight: bold;
    @media (max-width: 800px) {
        font-size: 30px;
    }
}

.productFavoriteContainer > button {
    width: 64px;
    height: 56px;
    border-radius: 9px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: rgba($color: #838383, $alpha: 0.5);
    @media (max-width: 800px) {
        width: 50px;
        height: 50px;
    }
    & > img {
        width: 24px;
    }
}