.followUpOrderContainer {
    width: 90%;
    margin: 0px auto 10px;
    border-radius: 30px;
    direction: rtl;
    padding: 40px 30px;
    @media (max-width: 800px) {
        padding: 40px 20px;
        width: calc(100% - 40px);
        margin: 0px auto 10px;
    }
    & > div:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        & > img {
            width: 120px;
            display: block;
            margin-bottom: 20px;
            @media (max-width: 800px) {
                width: 80px;
            }
        }
        & > p {
            font-family: Aviny;
            margin: 0px;
            font-size: 24px;
            color: black;
            & > span {
                color: #BD945B;
            }
        }
        & > p:nth-child(3) {
            margin: 5px 0px;
        }
        & > p:nth-child(4) {
            color: #BD945B;
            font-size: 20px;
            font-family: IranYekan;
        }
    }
}

.progressBarContainerForFollowUpOrder {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    & > div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 0px auto;
        direction: rtl;
        @media (max-width: 800px) {
            width: calc(100%);
        }
        & > div:nth-child(2n + 1) {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F3F3F3;
            @media (max-width: 800px) {
                width: 40px;
                height: 40px;
            }
            & > img {
                width: 25px;
                display: block;
            }
        }
        & > div:nth-child(2n) {
            width: calc((100% - 228px)/ 3);
            background-color: #F3F3F3;
            height: 1px;
        }
    }
    & > div:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 10px auto;
        direction: rtl;
        width: 57%;
        @media (max-width: 800px) {
            width: 66%;
        }
        & > p {
            font-family: Aviny;
            font-size: 28px;
            margin: 0px;
            @media (max-width: 800px) {
                font-size: 14px;
            }
        }
        & > p:nth-child(1) {
            margin-right: 40px;
            @media (max-width: 800px) {
                margin-right: 17px;
            }
        }
        & > p:nth-child(2) {
            margin-right: 92px;
            @media (max-width: 800px) {
                margin-right: 28px;
            }
        }
        & > p:nth-child(3) {
            margin-right: 32px;
            @media (max-width: 800px) {
                margin: 0px;
            }
        }
    }
}

.enterTrackingCodeContainer {
    display: flex;
    flex-direction: column;   
    align-items: center;
    margin: 50px 0px;
    & > input {
        width: 40%;
        border: 1px solid #E4E4E4;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px;
        font-size: 15px;
        font-family: IRANYekan;
        @media (max-width: 800px) {
            width: 80%;
        }
    }
    & > input:focus {
        outline: none;
    }
    & > input::placeholder {
        font-size: 15px;
        text-align: right;
    }
    & > button {
        margin-top: 30px;
        width: 20%;
        padding: 10px 0px;
        color: white;
        background-color: #E15A04;
        border: none;
        border-radius: 12px;
        font-family: Aviny;
        font-size: 28px;
        cursor: pointer;
        @media (max-width: 800px) {
            width: 50%;
        }
    }
}

.followUpItemsContainer {
    margin-bottom: 20px;
    width: 80%;
    margin: 0px auto;
    & > p {
        font-family: Aviny;
        font-size: 36px;
        color: #000000;
        margin: 20px auto 30px;
        border-bottom: 1px solid rgba(228, 228, 228, 0.63);
        direction: rtl;
        padding-bottom: 20px;
        width: 100%;
        @media (max-width: 800px) {
            font-size: 24px;
        }
    }
    & > div:last-child {
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 20px;
        row-gap: 20px;
        direction: rtl;
        border-bottom: 1px solid rgba(228, 228, 228, 0.63);
        padding-bottom: 20px;
        @media (max-width: 800px) {
            grid-template-columns: 100%;
            column-gap: 0px;
            row-gap: 20px;
        }
        & > div {
            display: grid;
            grid-template-columns: 50% 50%;
            
            & > p {
                margin: 0px;
                display: flex;
                align-items: center;
            }
            & > p:first-child {
                font-size: 24px;
                font-family: Aviny;
            }
            & > p:last-child {
                font-size: 16px;
                font-family: IRANYekan;
            }
        }
    }
}

.followUpOrderItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(228, 228, 228, 0.63); 
    padding-bottom: 25px;
    margin-bottom: 25px;
    direction: rtl;
    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
    & > div:first-child {
        display: flex;
        flex-direction: row;
        @media (max-width: 800px) {
            flex-direction: column;
            width: 100%;
        }
        & > img {
            width: 235px;
            height: 180px;
            object-fit: cover;
            @media (max-width: 800px) {
                width: 100%;
            }
        }
        & > div {
            height: 180px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 15px;
            @media (max-width: 800px) {
                margin-right: 0px;
                height: auto;
            }
            & > p:nth-child(1) {
                font-family: Aviny;
                font-size: 30px;
                color: #000000;
                margin: 0px;
                @media (max-width: 800px) {
                    font-size: 24px;
                }
            }
            & > div:nth-child(2) {
                display: flex;
                flex-direction: row;
                align-items: center;
                & > p {
                    font-family: IRANYekan;
                    font-weight: bold;
                    font-size: 16px;
                    color: #000000;
                    margin: 0px;
                    @media (max-width: 800px) {
                        font-size: 14px;
                    }
                }
                & > div:nth-child(2) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    background: #FFFFFF;
                    box-sizing: border-box;
                    border-radius: 5px;
                    padding: 0px 10px;
                    margin-right: 15px;
                    & > p {
                        font-family: IRANYekan;
                        font-weight: bold;
                        font-size: 20px;
                        color: #000000;
                        margin: 0px;
                        @media (max-width: 800px) {
                            font-size: 14px;
                        }
                    }
                    & > input:nth-child(2) {
                        margin: 0px 15px;
                        border: none;
                        width: 50px;
                        text-align: center;
                        font-family: IRANYekan;
                        height: 90%;
                    }
                    & > input:focus {
                        outline: none;
                    }
                    & > p:nth-child(2) {
                        margin: 0px 15px;
                    }
                    & > p:nth-child(1), :nth-child(3) {
                        font-size: 28px;
                        cursor: pointer;
                        @media (max-width: 800px) {
                            font-size: 20px;
                        }
                    }
                }
                & > select {
                    height: 50px;
                    margin-right: 10px;
                    font-family: IRANYekan;
                    border: 1px solid black;
                    border-radius: 8px;
                    padding: 0px 10px;
                }
            }
            & > div:nth-child(3) {
                display: flex;
                flex-direction: row;
                align-items: center;
                & > p {
                    margin: 0px;
                    font-family: IRANYekan;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    @media (max-width: 800px) {
                        font-size: 12px;
                    }
                }
                & > p:last-child {
                    margin-right: 10px;
                }
            }
        }
    }   
    & > div:last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 800px) {
            width: 100%;
        }
        & > div:nth-child(1) {
            display: flex;
            flex-direction: row;
            align-items: center;
            @media (max-width: 800px) {
                margin-top: 10px;
            }
            & > p {
                font-family: IRANYekan;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                color: #000000;
                margin: 0px 0px 0px 10px;
                @media (max-width: 800px) {
                    font-size: 14px;
                }
            }
            & > img {
                width: 24px;
                display: block;
                @media (max-width: 800px) {
                    width: 20px;
                }
            }
        }
        & > div:nth-child(2) {
            display: flex;
            flex-direction: row;
            align-items: center;
            & > p:first-child {
                font-family: IRANYekan;
                font-weight: bold;
                font-size: 18px;
                color: #767676;
                margin: 0px;
                @media (max-width: 800px) {
                    font-size: 14px;
                }
            }
            & > p:last-child {
                font-family: IRANYekan;
                font-weight: bold;
                font-size: 18px;
                color: #000000;
                margin: 0px 10px 0px 0px;
                @media (max-width: 800px) {
                    font-size: 14px;
                }
            }
        }
        & > a > button {
            width: 228px;
            height: 52px;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: IRANYekan;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            background-color: white;
            color: #E45803;
            border: 1px solid #E45803;
            @media (max-width: 800px) {
                width: 80%;
                margin: 0px auto;
            }
        }
        & > a > button:focus {
            outline: none;
        }
        & > a > button:hover {
            background-color: #E45803;
            color: white;
        }
    }
}

.followUpDetails {
    width: 80%;
    margin: 20px auto 0px;
    direction: rtl;
    & > p {
        font-family: Aviny;
        margin: 20px 0px 0px;
        font-size: 20px;
        color: black;
        display: flex;
        flex-direction: row;
        & > img {
            width: 16px;
            margin-left: 8px;
            object-fit: contain;
        }
    }
}
