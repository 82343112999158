.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        direction: rtl;
        & > span {
            font-size: 48px;
            line-height: 47px;
            font-weight: bold;
            font-family: 'Aviny';
            @media (max-width: 800px) {
                font-size: 36px;
            }
        }
        & > span:first-child {
            color: #C69C61;
        }
        & > span:last-child {
            margin-right: 8px;
        }
    }
    & > img {
        width: 400px;
        margin-bottom: 50px;
        margin-top: 30px;
        @media (max-width: 800px) {
            width: calc(100% - 40px);
        }
    }
}

.allFeaturesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 800px;
    margin: 0px auto 24px;
    direction: rtl;
    @media (max-width: 800px) {
        width: 90%;
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        row-gap: 20px;
        column-gap: 20px;
    }
    & > button {
        padding: 5px 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid white;
        color: white;
        direction: rtl;
        border-radius: 10px;
        cursor: pointer;
        & > p {
            margin: 0px 12px 0px 0px;
            font-family: Aviny;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            @media (max-width: 800px) {
                font-size: 14px;
            }
        }
        & > img {
            width: 24px;
            height: 24px;
            @media (max-width: 800px) {
                width: 20px;
                height: 20px;
            }
        }
    }
    & > button:hover {
        background-color: lightgray;
        color: black;
    }
    & > button:focus {
        outline: none;
    }
}

.toomarInputContainer {
    display: flex;
    flex-direction: column;
    direction: rtl;
    & > p:nth-child(1) {
        font-family: Aviny;
        font-size: 24px;
        line-height: 38px;
        margin: 0px 0px 6px;
    }
    & > input {
        width: 100%;
        border: 1px solid #E4E4E4;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px;
        font-size: 15px;
    }
    & > input:focus {
        outline: none;
    }
    & > input::placeholder {
        font-size: 15px;
    }
    & > textarea {
        width: 100%;
        border: 1px solid #E4E4E4;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px;
        font-size: 15px;
        resize: none;
    }
    & > textarea:focus {
        outline: none;
    }
    & > textarea::placeholder {
        font-size: 15px;
    }
    & > p:nth-child(3) {
        font-family: Aviny;
        font-size: 16px;
        line-height: 24px;
        margin: 0px 0px 0px;
        color: red;
    }
    
}

.followUsInSocialsContainer {
    flex-direction: column!important;
    justify-content: center;
    align-items: center;
    display: flex;
    & > p {
        font-family: Aviny;
        font-size: 24px;
        color: #000000;
        margin: 0px 0px 20px;
    }
    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 200px;
        & > a {
            background-color: rgba(247, 222, 215, 0.47);
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}

.collapseBarContainer {
    width: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
    border-radius: 10px;
    margin-bottom: 30px;
    direction: rtl;
    padding: 24px 35px;
    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        & > span {
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
            color: #000000;
            @media (max-width: 800px) {
                font-size: 16px;
            }
        }
        & > img {
            width: 49px;
            cursor: pointer;
            @media (max-width: 800px) {
                width: 40px;
            }
        }
    }
    & > .responseOpen {
        display: block;
        color: #757575;
        font-size: 16px;
        font-weight: bold;
        margin: 10px 0px 0px;
        transition: display ease-in 2000ms;
        @media (max-width: 800px) {
            font-size: 14px;
        }
    }
    & > .responseClose {
        display: none;
    }
}

.radioButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > button {
        width: 25px;
        height: 25px;
        background-color: white;
        border: 1px solid #E4E4E4;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        & > img {
            width: 12px;
            display: block;
        }
    }
    & > p {
        font-family: IRANYekan;
        font-weight: bold;
        font-size: 16px;
        color: #000000;
        margin: 0px 13px 0px 0px;
    }
}
