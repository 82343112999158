.buyProcessContainer {
    margin-bottom: 20px;
    margin: 0px auto;
    & > p {
        font-family: Aviny;
        font-size: 36px;
        color: #000000;
        margin: 20px auto 30px;
        border-bottom: 1px solid rgba(228, 228, 228, 0.63);
        direction: rtl;
        padding-bottom: 20px;
        width: 100%;
        @media (max-width: 800px) {
            font-size: 30px;
        }
    }
    @media (max-width: 800px) {
        width: calc(100% - 40px);
        margin: 0px auto;
    }
}


.progressBarContainerForBuyProcess {
    display: flex;
    flex-direction: column;
    direction: rtl;
    background: #F2F2F2;
    padding: 40px 120px;
    @media (max-width: 800px) {
        padding: 10px 20px;
    }
    & > div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 98%;
        margin: 0px auto;
        & > div:nth-child(2n + 1) {
            width: 57px;
            height: 57px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #E9E9E9;
            @media (max-width: 800px) {
                width: 40px;
                height: 40px;
            }
            & > img {
                width: 25px;
                display: block;
            }
        }
        & > div:nth-child(2n) {
            width: calc((100% - 228px)/ 3);
            background: #E9E9E9;
            height: 1px;
        }
    }
    & > div:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 10px auto;
        width: 100%;
        @media (max-width: 800px) {
            width: 95%;
            margin: 0px auto;
        }
        & > p {
            font-family: Aviny;
            font-size: 28px;
            margin: 0px;
            @media (max-width: 800px) {
                font-size: 16px;
            }
        }
    }
}

.progressBarContainerForBuyProcessDigital {
    display: flex;
    flex-direction: column;
    direction: rtl;
    background: #F2F2F2;
    padding: 40px 120px;
    @media (max-width: 800px) {
        padding: 10px 20px;
    }
    & > div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 98%;
        margin: 0px auto;
        @media (max-width: 800px) {
            width: 95%;
            margin: 0px auto;
        }
        & > div:nth-child(2n + 1) {
            width: 57px;
            height: 57px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #E9E9E9;
            @media (max-width: 800px) {
                width: 40px;
                height: 40px;
            }
            & > img {
                width: 25px;
                display: block;
            }
        }
        & > div:nth-child(2n) {
            width: calc((100% - 228px)/ 2);
            background: #E9E9E9;
            height: 1px;
        }
    }
    & > div:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 10px auto;
        width: 98%;
        @media (max-width: 800px) {
            width: 80%;
            margin: 0px auto;
        }
        & > p {
            font-family: Aviny;
            font-size: 28px;
            margin: 0px;
            @media (max-width: 800px) {
                font-size: 16px;
            }
        }
    }
}

.selectTextContainer {
    width: 80%;
    margin: 0px auto;
    & > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 40px auto 30px;
        width: 100%;
        direction: rtl;
        @media (max-width: 800px) {
            flex-direction: column;
            align-items: flex-start;
        }
        & > p {
            font-family: Aviny;
            font-size: 24px;
            color: #000000;
            margin: 0px 0px 0px 40px;
        }
        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            direction: rtl;
            @media (max-width: 800px) {
                width: 100%;
            }
            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 66px;
                margin-right: 50px;
                cursor: pointer;
                @media (max-width: 800px) {
                    width: 20%;
                    margin-right: 0px;
                }
                & > .isNotActive {
                    font-weight: bold;
                    font-size: 18px;
                    color: black;
                    margin: 0px 0px 10px;
                    @media (max-width: 800px) {
                        font-size: 14px;
                    }
                }
                & > .isActive {
                    font-weight: bold;
                    font-size: 18px;
                    color: #EABA77;
                    margin: 0px 0px 10px;
                    @media (max-width: 800px) {
                        font-size: 14px;
                    }
                }
                & > img {
                    width: 100%;
                   
                }
            }
        }
    }
    & > p:nth-child(2) {
        font-family: Aviny;
        font-size: 36px;
        color: #000000;
        margin: 0px 0px 20px 0px;
        direction: rtl;
        @media (max-width: 800px) {
            font-size: 24px;
        }
    }
    & > div:nth-child(3)::-webkit-scrollbar {
        display: none;
    }
    & > div:nth-child(3) {
        display: grid;
        grid-template-columns: calc((100% - 60px)/3) calc((100% - 60px)/3) calc((100% - 60px)/3);
        column-gap: 30px;
        direction: rtl;
        margin-bottom: 40px;
        row-gap: 50px;
        height: 100vh;
        overflow-y: scroll;
        -ms-overflow-style: none;
        align-items: flex-start;
        & > button:nth-child(1) {
            width: 228px;
            height: 52px;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: IRANYekan;
            font-weight: bold;
            font-size: 18px;
            cursor: pointer;
            margin-top: 20px;
            @media (max-width: 800px) {
                margin-bottom: 20px;
            }
        }
        & > button:nth-child(1):focus {
            outline: none;
        }
        & > button:nth-child(1) {
            background-color: #E15A04;
            border: none;
            color: white;
        }
        & > button:nth-child(1):focus {
            background-color: white;
            border: 1px solid #E45803;
            color: #E45803;
        }
        @media (max-width: 800px) {
            grid-template-columns: 100%;
            column-gap: 0px;
            row-gap: 50px;
        }
        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            & > img {
                width: 100%;
                display: block;
            }   
            & >  button {
                font-family: IRANYekan;
                font-weight: bold;
                font-size: 18px;
                color: #FFFFFF;
                width: 228px;
                height: 68px;
                background: #E45803;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                cursor: pointer;
            }
            & > button:focus {
                outline: none;
            }
            & > button:hover {
                background-color: white;
                border: 1px solid #E45803;
                color: #E45803;
            }
        }
    }
    & > div:nth-child(4) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        direction: rtl;
        margin-bottom: 30px;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        & > button {
            width: 228px;
            height: 52px;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: IRANYekan;
            font-weight: bold;
            font-size: 18px;
            cursor: pointer;
            @media (max-width: 800px) {
                margin-bottom: 20px;
            }
        }
        & > button:focus {
            outline: none;
        }
        & > button:nth-child(2) {
            border: 1px solid #E45803;
            color: #E45803;
            background-color: white;
        }
        & > button:nth-child(1) {
            background-color: #E15A04;
            border: none;
            color: white;
        }
        & > button:hover {
            background-color: white;
            border: 1px solid #E45803;
            color: #E45803;
        }
    }
}

.selectTitleContainer {
    width: 80%;
    margin: 0px auto;
    & > .titleName {
        font-family: Aviny;
        font-size: 36px;
        color: #000000;
        margin: 0px 0px 20px 0px;
        direction: rtl;
        @media (max-width: 800px) {
            font-size: 26px;
            margin-top: 20px;
        }
    }
    & > .typeOfPrint {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 370px;
        margin: 20px auto;
        @media (max-width: 800px) {
            width: 100%;
        }
        & > button {
            width: 174px;
            height: 51px;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 10px;
            color: black;
            font-family: Aviny;
            font-size: 24px;
            background-color: white;
            cursor: pointer;
            @media (max-width: 800px) {
                width: 45%;
                font-size: 20px;

            }
        }
        & > button:focus {
            outline: none;
        }
        & > button:hover {
            background-color: black;
            color: white;
        }
    }
    & > .typeOfPlan {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        direction: rtl;
        margin: 20px auto;
        @media (max-width: 800px) {
            width: 100%;
            flex-direction: column;
        }
        & > button {
            width: 250px;
            height: 51px;
            box-sizing: border-box;
            font-family: Aviny;
            font-size: 24px;
            border-radius: 10px;
            cursor: pointer;
            color: white;
            @media (max-width: 800px) {
                width: 80%;
                margin-bottom: 20px;
            }
        }
        
        & > button:focus {
            outline: none;
        }
    }
    & > .typeOfPrintLatin {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 850px;
        margin: 20px auto;
        direction: rtl;
        @media (max-width: 800px) {
            width: 100%;
            flex-direction: column;
        }
        & > button {
            width: 264px;
            height: 51px;
            box-sizing: border-box;
            font-family: Aviny;
            font-size: 24px;
            border-radius: 10px;
            cursor: pointer;
            color: white;
            @media (max-width: 800px) {
                width: 80%;
                margin-bottom: 20px;
            }
        }
        & > button:nth-child(2) {
            border: 1px solid #DC9300;
            background-color: #DC9300;
        }
        & > button:nth-child(2):hover {
            background-color: white;
            border: 1px solid #DC9300;
            color: #DC9300;
        }
        & > button:nth-child(3) {
            border: 1px solid #747474;
            background-color: #747474;
        }
        & > button:nth-child(3):hover {
            background-color: white;
            border: 1px solid #747474;
            color: #747474;
        }
        & > button:nth-child(1) {
            border: 1px solid black;
            background-color: black;
        }
        & > button:nth-child(1):hover {
            background-color: white;
            border: 1px solid black;
            color: black;
        }
        & > button:focus {
            outline: none;
        }
    }
    & > .typeOfTitr {
        width: 100%;
        margin: 20px auto 40px;
        &  > .titleType {
            & > .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
                color: black;
            }
            & > .swiper-button-next, .swiper-rtl .swiper-button-prev {
                color: black !important;
            }
            & > .swiper-button-prev, .swiper-rtl .swiper-button-next {
                color: black !important;
            }
            & > .swiper-pagination {
                bottom: -5px;
            }
            & > .swiper-pagination > .swiper-pagination-bullet-active {
                background-color: #E45803;
            }
            & > .swiper-wrapper {
                & > div {
                    text-align: center;
                }
            }
        }
    }
    & > .backOrNextContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        direction: rtl;
        margin-bottom: 30px;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        & > button {
            width: 228px;
            height: 52px;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: IRANYekan;
            font-weight: bold;
            font-size: 18px;
            cursor: pointer;
            @media (max-width: 800px) {
                margin-bottom: 20px;
            }
        }
        & > button:focus {
            outline: none;
        }
        & > button:nth-child(1) {
            border: 1px solid #E45803;
            color: #E45803;
            background-color: white;
        }
        & > button:nth-child(1):hover {
            background-color: #E45803;
            border: 1px solid #E45803;
            color: white;
        }
        & > button:nth-child(2) {
            background-color: #E15A04;
            border: none;
            color: white;
        }
        & > button:nth-child(2):hover {
            background-color: white;
            border: 1px solid #E45803;
            color: #E45803;
        }
    }
}

.addDataContainer {
    width: 80%;
    margin: 0px auto;
    & > p:nth-child(1) {
        font-family: Aviny;
        font-size: 36px;
        color: #000000;
        margin: 0px 0px 20px 0px;
        direction: rtl;
        @media (max-width: 800px) {
            font-size: 26px;
            margin-top: 20px;
        }
    }
    & > div:nth-child(2) {
        display: grid;
        grid-template-columns: calc((100% - 60px)/3) calc((100% - 60px)/3) calc((100% - 60px)/3);
        column-gap: 30px;
        margin-bottom: 30px;
        direction: rtl;
        @media (max-width: 800px) {
            grid-template-columns: calc(50% - 10px) calc(50% - 10px);
            row-gap: 20px;
            column-gap: 20px;
            
        }
    }
    // & > p:nth-child(3) {
    //     font-family: Aviny;
    //     font-size: 36px;
    //     color: #000000;
    //     margin: 0px 0px 20px 0px;
    //     direction: rtl;
    //     @media (max-width: 800px) {
    //         font-size: 26px;
    //         margin-top: 20px;
    //     }
    // }
    // & > div:nth-child(4) {
    //     display: grid;
    //     grid-template-columns: calc((100% - 60px)/3) calc((100% - 60px)/3) calc((100% - 60px)/3);
    //     column-gap: 30px;
    //     margin-bottom: 30px;
    //     direction: rtl;
    //     @media (max-width: 800px) {
    //         grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    //         row-gap: 20px;
    //         column-gap: 20px;
            
    //     }
    // }
    // & > p:nth-child(3) {
    //     font-family: Aviny;
    //     font-size: 36px;
    //     color: #000000;
    //     margin: 0px 0px 20px 0px;
    //     direction: rtl;
    //     @media (max-width: 800px) {
    //         font-size: 26px;
    //         margin-top: 20px;
    //     }
    // }
    // & > div:nth-child(4) {
    //     direction: rtl;
    //     margin: 0px 0px 30px;
    //     & > select {
    //         height: 53px;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         background: #FFFFFF;
    //         border: 1px solid #E4E4E4;
    //         box-sizing: border-box;
    //         border-radius: 5px;
    //         font-family: IRANYekan;
    //         font-style: normal;
    //         font-weight: bold;
    //         font-size: 18px;
    //         color: #000000;
    //         padding: 0px 20px;
    //         @media (max-width: 800px) {
    //             font-size: 14px;
    //             height: 40px;
    //         }
    //     }
    //     & > select:focus {
    //         outline: none;
    //     }
    // }
    & > p:nth-child(3) {
        font-family: Aviny;
        font-size: 36px;
        color: #000000;
        margin: 0px 0px 20px 0px;
        direction: rtl;
        @media (max-width: 800px) {
            font-size: 26px;
            margin-top: 20px;
        }
    }
    & > div:nth-child(4) {
        direction: rtl;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        margin-left: 30%;
        margin-bottom: 20px;
        @media (max-width: 800px) {
            width: 100%;
            margin-bottom: 10px;
            margin-left: 0px;
            flex-direction: column;
            align-items: flex-start;
        }
        & > p {
            font-family: Aviny;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            color: #000000;
            margin: 0px;
            @media (max-width: 800px) {
                font-size: 18px;
            }
        }
        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            & > div >input {
                width: 90%!important;
            }
            & > div > input:focus {
                outline: none;
            }
        }
    }
    & > div:nth-child(5) {
        direction: rtl;
        margin: 0px 0px 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: 800px) {
            flex-direction: column;
            align-items: flex-start;
        }
        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 40%;
            @media (max-width: 800px) {
                width: 100%;
                margin-bottom: 10px;
                flex-direction: column;
                align-items: flex-start;
            }
            & > p {
                font-family: Aviny;
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                color: #000000;
                margin: 0px;
                @media (max-width: 800px) {
                    font-size: 18px;
                }
            }
            & > div {
                display: flex;
                flex-direction: row;
                align-items: center;
                @media (max-width: 800px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                & > div > select {
                    height: 53px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #FFFFFF;
                    border: 1px solid #E4E4E4;
                    box-sizing: border-box;
                    border-radius: 5px;
                    font-family: IRANYekan;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    color: #000000;
                    padding: 0px 20px;
                    margin: 0px 15px;
                    @media (max-width: 800px) {
                        font-size: 14px;
                        height: 40px;
                    }
                }
                & > div > select:focus {
                    outline: none;
                }
                & > div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    @media (max-width: 800px) {
                        margin-top: 20px;
                    }
                    
                    & > p {
                        font-family: Aviny;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        color: #000000;
                        margin: 0px 0px 0px 30px;
                        @media (max-width: 800px) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        & > div:nth-child(2) {
            margin-right: 30px;
            @media (max-width: 800px) {
                margin-right: 0px;
            }
        }
    }
    & > div:nth-child(6) {
        margin-bottom: 40px;
        display: grid;
        grid-template-columns: 70% 20%;
        column-gap: 10%;
        direction: rtl;
        @media (max-width: 800px) {
            grid-template-columns: 100%;
        }
        & > div > input {
            width: 100%;
            @media (max-width: 800px) {
                width: 100%;
            }
        }
    }
    & > div:nth-child(7) {
        margin-bottom: 40px;
        & > div > input {
            width: 100%;
            @media (max-width: 800px) {
                width: 100%;
            }
        }
    }
    & > p:nth-child(8) {
        font-family: Aviny;
        font-size: 36px;
        color: #000000;
        margin: 0px 0px 20px 0px;
        direction: rtl;
        @media (max-width: 800px) {
            font-size: 26px;
            margin-top: 20px;
        }
    }
    & > div:nth-child(9) {
        display: grid;
        grid-template-columns: calc((100% - 40px)/2) calc((100% - 40px)/2);
        column-gap: 40px;
        margin-bottom: 30px;
        direction: rtl;
        width: 70%;
        margin-left: 30%;
        @media (max-width: 800px) {
            grid-template-columns: calc(50% - 10px) calc(50% - 10px);
            column-gap: 20px;
            width: 100%;
            margin-left: 0px;
        }
        & > div > input {
            @media (max-width: 800px) {
                width: 100%;
            }
        }
    }
    & > div:nth-child(9) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        direction: rtl;
        margin-bottom: 30px;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        & > button {
            width: 228px;
            height: 52px;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: IRANYekan;
            font-weight: bold;
            font-size: 18px;
            cursor: pointer;
            @media (max-width: 800px) {
                margin-bottom: 20px;
            }
        }
        & > button:focus {
            outline: none;
        }
        & > button:nth-child(1) {
            border: 1px solid #E45803;
            color: #E45803;
            background-color: white;
        }
        & > button:nth-child(1):hover {
            background-color: #E45803;
            border: 1px solid #E45803;
            color: white;
        }
        & > button:nth-child(2) {
            background-color: #E15A04;
            border: none;
            color: white;
        }
        & > button:nth-child(2):hover {
            background-color: white;
            border: 1px solid #E45803;
            color: #E45803;
        }
    }
}

.acceptDataContainer {
    width: 80%;
    margin: 0px auto;
    & > p:nth-child(1) {
        font-family: Aviny;
        font-size: 36px;
        color: #000000;
        margin: 0px 0px 20px 0px;
        direction: rtl;
        @media (max-width: 800px) {
            font-size: 26px;
            margin-top: 20px;
        }
    }
    & > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        & > div {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(228, 228, 228, 0.63);
            direction: rtl;
            padding-bottom: 15px;
            margin-bottom: 15px;
            & > p {
                font-family: Aviny;
                font-size: 30px;
                color: #000000;
                margin: 0px;
                width: 200px;
                @media (max-width: 800px) {
                    font-size: 16px;
                }
            }
            & > p:nth-child(2) {
                display: flex;
                justify-content: center;
            }
            & > p:nth-child(3) {
                display: flex;
                justify-content: flex-end;
            }
            & > div {
                width: 200px;
                & > img {
                    width: 30px;
                    cursor: pointer;
                    display: block;
                    @media (max-width: 800px) {
                        width: 24px;
                    }
                }
            }
            & > div:nth-child(2) {
                display: flex;
                justify-content: center;
            }
            & > div:nth-child(3) {
                display: flex;
                justify-content: flex-end;
            }
        }
        & > div:nth-child(1) {
            & > p {
                color: #916F40;
            }
        }
        & > div:last-child {
            border-bottom: none;
        }
    }
    & > p:nth-child(3) {
        font-family: Aviny;
        font-size: 36px;
        color: #000000;
        margin: 0px 0px 20px 0px;
        direction: rtl;
        @media (max-width: 800px) {
            font-size: 26px;
            margin-top: 20px;
        }
    }
    & > p:nth-child(4), p:nth-child(5), p:nth-child(6), p:nth-child(7), p:nth-child(8), p:nth-child(9), p:nth-child(10), p:nth-child(11), p:nth-child(12), :nth-child(13), :nth-child(14) {
        font-family: IRANYekan;
        font-size: 18px;
        text-align: right;
        color: #000000;
        margin: 0px 0px 30px;
        direction: rtl;
        @media (max-width: 800px) {
            font-size: 14px;
        }
    }
    & > div:nth-child(15) {
        direction: rtl;
        margin: 0px 0px 40px;
        & > div > p {
            color: #E45803;
        }
    }
    & > div:nth-child(16) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        direction: rtl;
        margin-bottom: 30px;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        & > button {
            width: 228px;
            height: 52px;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: IRANYekan;
            font-weight: bold;
            font-size: 18px;
            cursor: pointer;
            @media (max-width: 800px) {
                margin-bottom: 20px;
            }
        }
        & > button:focus {
            outline: none;
        }
        & > button:nth-child(1) {
            border: 1px solid #E45803;
            color: #E45803!important;
            background-color: white;
        }
        & > button:nth-child(2) {
            background-color: #E15A04;
            border: none;
            color: white;
        }
    }
}

.changeStepContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    direction: rtl;
    margin-bottom: 30px;
    @media (max-width: 800px) {
        flex-direction: column;
    }
    & > button {
        width: 228px;
        height: 52px;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: IRANYekan;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        @media (max-width: 800px) {
            margin-bottom: 20px;
        }
    }
    & > button:focus {
        outline: none;
    }
    & > button:nth-child(2) {
        background-color: #E15A04;
        color: white;
        border: none;
        margin-right: 20px;
        @media (max-width: 800px) {
            margin-right: 0px;
        }
    }
    & > button:nth-child(1) {
        border: 1px solid #E45803;
        background-color: white;
        color: #E45803;
    }
}

.selected {
    background-color: lightblue!important;
    color: white;
}

.acceptDetailsReview {
    display: flex;
    flex-direction: column;
    padding: 40px;
    direction: rtl;
    & > .header {
        display: flex;  
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0px 0px 20px 0px;
        & > p:nth-child(1) {
            font-family: Aviny;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            color: #000000;
            margin: 0px;
        }
        & > div:nth-child(2) {
            display: flex;
            flex-direction: row;
            align-items: center;
            direction: rtl;
            & > p {
                font-family: IRANYekan;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                color: #000000;
                margin: 0px 0px 0px 5px;
            }
            & > img {
                width: 24px;
                height: 24px;
            }
        }
    }
    & > button:last-child {
        width: 228px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin-right: calc(100% - 228px);
        border: none;
        background: #E15A04;
        border-radius: 5px;
        cursor: pointer;
        & > p {
            font-family: IRANYekan;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 31px;
            color: #FFFFFF;
            margin: 0px 10px 0px 0px;
        }
    }
}

.cardTextImage {
    width: 537px;
    margin: 20px auto 40px;
    @media (max-width: 800px) {
        width: 100%;
    }
}

.titleDetailsContainer {
    display: flex;
    flex-direction: column;
    margin: 20px 0px 40px;
    & > div:nth-child(1) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        & > p {
            font-family: Aviny;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            margin: 0px 0px 20px;
            color: #000000;
        }
        & > button {
            width: 174px;
            height: 51px;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 10px;
            color: black;
            font-family: Aviny;
            font-size: 24px;
            background-color: white;
        }
    }
    & > div:nth-child(2) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 40px 0px;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        & > p {
            font-family: Aviny;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            margin: 0px 0px 20px;
            color: #000000;
        }
        & > button {
            width: 250px;
            height: 51px;
            box-sizing: border-box;
            font-family: Aviny;
            font-size: 24px;
            border-radius: 10px;
            color: white;
        }
    }
    & > div:nth-child(3) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0px 0px 40px 0px;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        & > p {
            font-family: Aviny;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            margin: 0px 0px 20px;
            color: #000000;
        }
        & > button {
            width: 250px;
            height: 51px;
            box-sizing: border-box;
            font-family: Aviny;
            font-size: 24px;
            border-radius: 10px;
            color: white;
        }
    }
    & > div:nth-child(4) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0px 0px 40px 0px;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        & > p {
            font-family: Aviny;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            margin: 0px 0px 20px;
            color: #000000;
        }
        & > img {
            width: 70%;
        }
    }
}

.dataDetailsContainer {
    display: flex;
    flex-direction: column;
    & > p:nth-child(1) {
        font-family: Aviny;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        color: #937040;
        margin: 0px;
    }
    & > div:nth-child(2) {
        margin: 30px 0px;
        padding-bottom: 30px;
        border-bottom: 1px solid #E4E4E4;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 800px) {
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 20px;
            row-gap: 20px;

        }
        & > p {
            font-family: Aviny;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            color: #232323;
            margin: 0px;
        }
    }
    // & > p:nth-child(3) {
    //     font-family: Aviny;
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 24px;
    //     color: #937040;
    //     margin: 0px;
    // }
    // & > div:nth-child(4) {
    //     margin: 30px 0px;
    //     padding-bottom: 30px;
    //     border-bottom: 1px solid #E4E4E4;
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     justify-content: space-between;
    //     @media (max-width: 800px) {
    //         display: grid;
    //         grid-template-columns: 50% 50%;
    //         column-gap: 20px;
    //         row-gap: 20px;
            
    //     }
    //     & > p {
    //         font-family: Aviny;
    //         font-style: normal;
    //         font-weight: 400;
    //         font-size: 20px;
    //         color: #232323;
    //         margin: 0px;
    //     }
    // }
    & > div:nth-child(3) {
        margin: 0px 0px 30px 0px;
        padding-bottom: 30px;
        border-bottom: 1px solid #E4E4E4;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 800px) {
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 20px;
            row-gap: 20px;
            
        }
        & > p {
            font-family: Aviny;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            color: #232323;
            margin: 0px;
        }
    }
    // & > div:nth-child(4) {
    //     margin: 0px 0px 30px 0px;
    //     padding-bottom: 30px;
    //     border-bottom: 1px solid #E4E4E4;
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     @media (max-width: 800px) {
    //         display: grid;
    //         grid-template-columns: 50% 50%;
    //         column-gap: 20px;
    //         row-gap: 20px;
            
    //     }
    //     & > p {
    //         font-family: Aviny;
    //         font-style: normal;
    //         font-weight: 400;
    //         font-size: 20px;
    //         color: #232323;
    //         margin: 0px;
    //     }
    //     & > p:nth-child(1) {
    //         margin-left: 50px;
    //     }
    // }
    & > div:nth-child(4) {
        margin: 0px 0px 30px 0px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: 800px) {
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 20px;
            row-gap: 20px;
            
        }
        & > p {
            font-family: Aviny;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            color: #232323;
            margin: 0px;
        }
        & > p:nth-child(1) {
            margin-left: 50px;
        }
    }
}

.black {
    border: 1px solid black;
    background-color: black;
    margin-left: 5px;
    margin-bottom: 5px;
}
.black:hover {
    background-color: white;
    border: 1px solid black;
    color: black;
}
.brown {
    border: 1px solid #4F2800;
    background-color: #4F2800;
    margin-left: 5px;
    margin-bottom: 5px;
}
.brown:hover {
    background-color: white;
    border: 1px solid #4F2800;
    color: #4F2800;
}
.red {
    border: 1px solid #A00800;
    background-color: #A00800;
    margin-left: 5px;
    margin-bottom: 5px;
}
.red:hover {
    background-color: white;
    border: 1px solid #A00800;
    color: #A00800;
}
.yellow {
    border: 1px solid #DC9300;
    background-color: #DC9300;
    margin-left: 5px;
    margin-bottom: 5px;
}
.yellow:hover {
    background-color: white;
    border: 1px solid #DC9300;
    color: #DC9300;
}
.gray {
    border: 1px solid #747474;
    background-color: #747474;
    margin-bottom: 5px;
}
.gray:hover {
    background-color: white;
    border: 1px solid #747474;
    color: #747474;
}
.gold {
    border: 1px solid #DC9300;
    background-color: #DC9300;
}
.gold:hover {
    background-color: white;
    border: 1px solid #DC9300;
    color: #DC9300;
}
.silver {
    border: 1px solid #747474;
    background-color: #747474;
    margin-left: 5px;
}
.silver:hover {
    background-color: white;
    border: 1px solid #747474;
    color: #747474;
}

.titleContainerBuyProcess {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    & > img {
        width: 80%;
        margin: auto;
        height: 200px;
        object-fit: contain;
    }
    & >  button {
        font-family: IRANYekan;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        width: 228px;
        height: 68px;
        background: #E45803;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
    }
    & > button:focus {
        outline: none;
    }
    & > button:hover {
        background-color: white;
        border: 1px solid #E45803;
        color: #E45803;
    }
}

.giftDateContainer {
    direction: rtl;
    margin: 0px 0px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
    }
    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        @media (max-width: 800px) {
            width: 100%;
            margin-bottom: 10px;
            flex-direction: column;
            align-items: flex-start;
        }
        & > p {
            font-family: Aviny;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            color: #000000;
            margin: 0px;
            @media (max-width: 800px) {
                font-size: 18px;
            }
        }
        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            @media (max-width: 800px) {
                flex-direction: column;
                align-items: flex-start;
            }
            & > div > select {
                height: 53px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFFFFF;
                border: 1px solid #E4E4E4;
                box-sizing: border-box;
                border-radius: 5px;
                font-family: IRANYekan;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                color: #000000;
                padding: 0px 20px;
                margin: 0px 15px;
                @media (max-width: 800px) {
                    font-size: 14px;
                    height: 40px;
                }
            }
            & > div > select:focus {
                outline: none;
            }
            & > div {
                display: flex;
                flex-direction: row;
                align-items: center;
                @media (max-width: 800px) {
                    margin-top: 20px;
                }
                
                & > p {
                    font-family: Aviny;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    color: #000000;
                    margin: 0px 0px 0px 30px;
                    @media (max-width: 800px) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    & > div:nth-child(2) {
        margin-right: 30px;
        @media (max-width: 800px) {
            margin-right: 0px;
        }
    }
}

.typeOfBegayi {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    direction: rtl;
    margin: 20px auto;
    width: 80%;
    @media (max-width: 800px) {
        width: 100%;
        flex-direction: column;
    }
    & > button {
        width: 200px;
        height: 51px;
        box-sizing: border-box;
        font-family: Aviny;
        font-size: 24px;
        border-radius: 10px;
        cursor: pointer;
        color: black;
        margin-left: 12px;
        @media (max-width: 800px) {
            width: 80%;
            margin-bottom: 20px;
        }
    }
    
    & > button:focus {
        outline: none;
    }
}
.typeOfBegayiTitle {
    width: 80%!important;
    font-family: Aviny!important;
    font-size: 36px!important;
    color: #000000!important;
    margin: 0px auto 20px!important;
    direction: rtl!important;
    border: none!important;
}


.twpStepProgress {
    display: flex;
    flex-direction: column;
    direction: rtl;
    background: #F2F2F2;
    padding: 40px 120px;
    @media (max-width: 800px) {
        padding: 10px 20px;
    }
    & > div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 98%;
        margin: 0px auto;
        @media (max-width: 800px) {
            width: 95%;
            margin: 0px auto;
        }
        & > div:nth-child(2n + 1) {
            width: 57px;
            height: 57px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #E9E9E9;
            @media (max-width: 800px) {
                width: 40px;
                height: 40px;
            }
            & > img {
                width: 25px;
                display: block;
            }
        }
        & > div:nth-child(2n) {
            width: calc((100% - 228px));
            background: #E9E9E9;
            height: 1px;
        }
    }
    & > div:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 10px auto;
        width: 98%;
        @media (max-width: 800px) {
            width: 80%;
            margin: 0px auto;
        }
        & > p {
            font-family: Aviny;
            font-size: 28px;
            margin: 0px;
            @media (max-width: 800px) {
                font-size: 16px;
            }
        }
    }
}