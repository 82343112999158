.whyToomarContainer {
    width: 90%;
    margin: 0px auto 80px;
    border: 2px solid #C69C61;
    box-sizing: border-box;
    border-radius: 30px;
    direction: rtl;
    padding: 40px 30px;
    & > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        & > div {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            margin: 0px 0px 20px;
            & > p {
                font-family: Aviny;
                font-size: 30px;
                color: #9D9176;
                margin: 0px 15px 0px 0px;
                @media (max-width: 800px) {
                    font-size: 20px;
                }
            }
            & > img {
                width: 75px;
                height: 75px;
                display: block;
                padding-bottom: 5px;
                border-bottom: 1px solid black;
            }
        }
    }
    & > :not(div:last-child) > p {
        font-family: Aviny;
        font-style: normal;
        font-size: 18px;
        text-align: right;
        color: #000000;
        margin: 0px;
        @media (max-width: 800px) {
            font-size: 14px;
        }
        
    }
}