.favoritesContainer {
    width: 90%;
    margin: 0px auto 80px;
    border-radius: 30px;
    direction: rtl;
    padding: 40px 30px;
    @media (max-width: 800px) {
        padding: 40px 20px;
        width: calc(100% - 40px);
        margin: 0px auto 80px;
    }
    & > p {
        font-family: Aviny;
        font-style: normal;
        font-size: 24px;
        text-align: right;
        color: #000000;
        margin: 0px 0px 20px;
        @media (max-width: 800px) {
            font-size: 20px;
        }
    }
    & > div {
        display: grid;
        grid-template-columns: calc(32.33333% - 10px) calc(32.33333% - 10px) calc(32.3333% - 10px);
        column-gap: 15px;
        row-gap: 15px;
        & > div {
            width: 100%;
        }
        @media (max-width: 800px) {
            grid-template-columns: 100%;
        }
    }
}

.priceFavorites {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    direction: ltr;
    & > span:nth-child(1) {
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        color: #000000;
    }
    & > span:nth-child(2) {
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        color: #000000;
        margin: 0px 10px;
    }
    & > span:nth-child(3) {
        font-weight: bold;
        font-size: 12px;
        line-height: 31px;
        color: #000000;
        text-decoration: line-through;
    }
}

.deleteContainer {
    cursor: pointer;
}